<template>
  <div>
    <b-card
      no-body
      class="mr-1"
    >
      <div class="pl-1 pt-1 ">
        <span class="saj-title">
          {{ $t("Search Filter") }}
        </span>
      </div>
      <div class="">
        <b-row
          class="px-1"
          style="display: flex; align-items: flex-end;"
        >
          <!-- <b-col
            cols="12"
            md="4"
            class="mb-md-0"
          >
            <label class="saj-text">{{ $t("District") }}:</label>
            <b-form-select
              v-model="selectedDistrict"
              :options="districtList"
              :value="districtList"
              size="lg"
              class="saj-text"
            />
          </b-col> -->
          <b-col
            cols="12"
            md="4"
            class="mb-md-0"
          >
            <label class="saj-text">{{ $t("Work Location") }}:</label>
            <b-form-select
              v-model="selectedWork"
              :options="WorkList"
              :value="WorkList"
              class="saj-text"
            />
            <!-- <b-form-select
              v-model="selectedDistrict"
              :options="districtList"
              :value="districtList"
              size="lg"
              class="saj-text"
            /> -->
          </b-col>
          <b-col
            cols="12"
            md="4"
            class="mb-md-0"
          >
            <label class="saj-text">{{ $t("Category") }}:</label>
            <b-form-select
              v-model="selectedCategory"
              :options="categoryList"
              :value="categoryList"
              class="saj-text"
            />
          </b-col>
          <b-col
            cols="12"
            md="4"
            class="mb-md-0 "
          >
            <label class="saj-text">{{ $t("Employee Id") }}:</label>
            <b-form-select
              v-model="selectedEmployeeID"
              :options="empIDList"
              :value="empIDList"
              class="saj-text"
            />
          </b-col>
          <b-col
            cols="12"
            md="4"
            class="mb-md-0 pb-1 mr-1 pt-1"
          >
            <label class="saj-text">{{ $t("Search Name") }}:</label>
            <b-form-input
              id="icons-search"
              v-model="searchname"
              :placeholder="$t('Search name')"
            />
          </b-col>
          <!-- Sini -->

          <b-col
            cols="2"
            class="p-0 m-0 mt-2 pt-2 pr-1 mb-1"
          >
            <b-button
              variant="primary"
              class="saj-button"
              block
              style=""
              @click="currentPage = 1, getDetails()"
            >
              <span class="d-md-none d-lg-block"> {{ $t("Search") }} </span>
            </b-button>
          </b-col>

          <!-- <b-row> -->
          <b-col
            cols="2"
            class="p-0 m-0 mt-2 pl-1 pt-2 mb-1 pr-2"
          >
            <b-button
              variant="primary"
              class="saj-button"
              block
              style=""
              @click="clearButton()"
            >
              <!-- <feather-icon icon="SearchIcon" /> -->
              <span class="d-md-none d-lg-block"> {{ $t("Clear") }} </span>
            </b-button>
          </b-col>
          <!-- </b-row> -->
        </b-row>
      </div>

      <b-overlay
        :show="show"
        rounded="sm"
      >

        <template #overlay>
          <div class="d-flex flex-column align-items-center justify-content-center">
            <b-spinner
              variant="primary"
              label="Spinning"
            />
            <p class="mt-1">
              {{ $t('Fetching Data') }}...
            </p>
          </div>

        </template>
        <div class="container">
          <b-table
            sticky-header="500px"
            responsive
            :items="employeeList"
            :fields="fields"
            bordered
            show-empty
            style=""
            class="mt-1 mb-1 saj-subtitle"
          >
            <!-- A custom formatted column -->
            <template #head()="data">
              <span
                class="saj-title"
              >{{ $t(data.label) }}</span>
            </template>
            <template #cell(id)="data">

              {{ $t(data.item.id) }}
            </template>
            <template #cell(name)="data">
              {{ $t(data.item.name) }}
            </template>
            <template #cell(category)="data">
              {{ $t(data.item.category) }}
            </template>
            <template #cell(work)="data">
              {{ $t(data.item.work_location) }}
            </template>
            <!-- kra -->
            <template
              #cell(jd_kra)="data"
            >

              <div
                :id="data.item.employee_name"
                class="d-flex justify-content-center"
                style="cursor:pointer;"
              >
                {{ data.item.jd_kra }}
              </div>
              <b-popover
                :target="data.item.employee_name"
                triggers="hover"
                placement="top"
              >
                <template #title>
                  <div
                    v-if="data.item.jd_kra_status === 0"
                    class="d-flex justify-content-center"
                  >
                    JD
                  </div>
                  <div
                    v-else
                    class="d-flex justify-content-center"
                  >
                    KRA
                  </div>
                </template>
                <!-- PERFORMANCE ID:  {{ data.item.performance_id }} -->
                <!-- JD Punya-->
                <template v-if="data.item.jd_kra_data !== null">
                  <div
                    v-if="data.item.jd_kra_status === 0"
                    class="container p-1 mb-1"
                    style="border-radius: 10px; background: #f7f7fc;"
                  >
                    <b>{{ $t("Job Task Score") }}: </b> {{ $t(data.item.jd_kra_data[0].job_task_score) }} <br>
                    <b>{{ $t("Job Subtask Score") }}:</b> {{ $t(data.item.jd_kra_data[0].job_subtask_score) }}
                  </div>
                </template>

                <!-- KRA Punya-->
                <div
                  v-else
                  class="container"
                >
                  <!-- KRA Exec -->
                  <div
                    v-if="data.item.category === 'Executive'"
                    class="container"
                  >

                    <!-- EXECUTIVE -->
                    <b-row
                      v-if="data.item.jd_kra_data === null"
                      class="p-1 mb-1 d-flex justify-content-center"
                    >
                      {{ $t("No KRA") }}
                    </b-row>
                    <b-row
                      v-for="(existitem, i) in data.item.jd_kra_data"
                      v-else
                      :id="existitem.id"
                      :key="'a' + i"
                      class="p-1 mb-1"
                      style="border-radius: 10px; background: #f7f7fc;"
                    >
                      <!-- {{ existitem }}ajjdkja -->
                      <b-row>
                        <b-col md="12">
                          <b>{{ $t("Objective Name") }}:</b> {{ $t(existitem.objective_name) }}
                        </b-col>
                        <b-col>
                          <b>{{ $t("Score") }}:</b> {{ $t(existitem.score) }}
                        </b-col>
                      </b-row>
                    </b-row>

                  </div>
                  <!-- KRA Non-Exec -->

                  <template v-else>
                    <div
                      v-if="data.item.jd_kra_data !== null"
                      class="container"
                    >
                      <!-- NON-EXECUTIVE -->
                      <b-row
                        v-if="data.item.jd_kra_data[0].main_task === null"
                        class="p-1 mb-1 d-flex justify-content-center"
                        style="border-radius: 10px; background: #f7f7fc;"
                      >
                        {{ $t("No Main Task") }}
                      </b-row>
                      <b-row
                        v-for="(existitem2, j) in data.item.jd_kra_data[0].main_task"
                        :id="existitem2.id"
                        :key="'b' + j"
                        class="p-1 mb-1"
                        style="border-radius: 10px; background: #f7f7fc;"
                      >
                        <b-row>
                          <b-col md="12">
                            <b>{{ $t("Main Task Name") }}:</b> {{ $t(existitem2.main_task_field) }}
                          </b-col>
                          <b-col>
                            <b>{{ $t("Main Task") }}:</b> {{ $t(existitem2.score) }}
                          </b-col>
                        </b-row>
                      </b-row>
                      <b-row
                        v-if="data.item.jd_kra_data[0].side_task === null"
                        class="p-1 mb-1 d-flex justify-content-center"
                        style="border-radius: 10px; background: #f7f7fc;"
                      >
                        {{ $t("No Additional Task") }}
                      </b-row>
                      <b-row
                        v-for="(existitem3, k) in data.item.jd_kra_data[0].side_task"
                        :id="existitem3.id"
                        :key="'c' + k"
                        class="p-1 mb-1"
                        style="border-radius: 10px; background: #f7f7fc;"
                      >
                        <b-row>
                          <b-col md="12">
                            <b>{{ $t("Additional Task Name") }}:</b> {{ $t(existitem3.side_task_summary) }}
                          </b-col>
                          <b-col>
                            <b>{{ $t("Additional Task") }}:</b> {{ $t(existitem3.score) }}
                          </b-col>
                        </b-row>
                      </b-row>

                    </div>
                  </template>

                </div>
              </b-popover>
            </template>
            <!-- kra -->
            <template #cell(competency_core)="data">

              <div class="d-flex justify-content-center">
                {{ data.item.competency_core }}
              </div>
            </template>
            <template #cell(competency_behaviour)="data">

              <div class="d-flex justify-content-center">
                {{ data.item.competency_behaviour }}
              </div>
            </template>
            <template #cell(training)="data">

              <div class="d-flex justify-content-center">
                {{ data.item.training }}
              </div>
            </template>
            <template #cell(contribution)="data">

              <div class="d-flex justify-content-center">
                {{ data.item.contribution }}
              </div>
            </template>
            <template #cell(total_score)="data">

              <div class="d-flex justify-content-center">
                {{ data.item.total_score }}
              </div>
            </template>
            <template #cell(action)="data">
              <div
                class="d-flex justify-content-center"
                style="
            color: #464e5f;
          "
              >{{ $t(data.item.userId) }}
                <feather-icon
                  v-b-modal.edit-employee
                  v-b-tooltip.hover.bottom="$t('Edit')"
                  icon="EditIcon"
                  style="color: orange; width: 1.5rem; height: 1.5rem"
                  class="mr-1"
                  @click="tempUser = data.item.user_id, tempPerf = data.item.performance_id"
                />
                <!-- {{ data.item.performance_approved_at }} -->
                <feather-icon
                  v-b-modal.approve-employee
                  v-b-tooltip.hover.bottom="$t('Approve')"
                  :style="data.item.performance_approved_at === null ? '' : 'display: none;'"
                  icon="CheckSquareIcon"
                  style="color: green; width: 1.5rem; height: 1.5rem"
                  @click="performanceId = data.item.performance_id"
                />
              <!-- @click="showApproveModal(data)" -->
              </div>
            </template></b-table>

          <b-row>
            <b-col>
              <span
                class="saj-text"
                align="start"
              >
                <!-- {{ $t("Showing") }} {{ rows > perPage*currentPage ? perPage*currentPage : rows }} {{ $t("from") }} {{ rows }} {{ $t("entries") }} -->
                {{ $t("Showing") }} {{ from }} {{ $t("to") }} {{ to }} {{ $t("of") }} {{ rows }} {{ $t("entries") }}
              </span>
            </b-col>

            <b-col>
              <div class="d-flex align-items-center justify-content-end mb-1">
                <i
                  style="margin-right: 5px"
                  class="fa-solid fa-angles-left saj-page"
                  @click="currentPage = 1, getDetails()"
                />
                <i
                  class="fa-solid fa-angle-left saj-page"
                  style=""
                  @click="currentPage !== 1 ? currentPage-- : currentPage === 1, getDetails()"
                />
                <validation-provider
                  #default="{ errors }"
                  :name="$t('Page')"
                  :rules="{
                    required,
                    max_value:lastPage,
                    min_value:1,
                  }"
                >
                  <b-form-input
                    v-model="currentPage"
                    class="p-0 text-center"
                    type="number"
                    style="width: 35px; height: 35px; background-color: red; color: white; border-radius: 20px; margin-right: 5px; margin-left: 5px;"
                    @change="currentPage > 0 && currentPage < lastPage + 1 ? getDetails() : ''"
                  />
                  <small
                    class="text-danger"
                    style="color: #EA5455 !important;
                          position: absolute;
                          white-space: nowrap;
                          right: 25px;
                        "
                  >{{ errors[0] }}</small>
                </validation-provider>
                <i
                  class="fa-solid fa-angle-right saj-page"
                  style=""
                  @click="currentPage < lastPage ? currentPage++ : currentPage === rows, getDetails()"
                />
                <i
                  class="fa-solid fa-angles-right saj-page"
                  style="margin-left: 5px"
                  @click="currentPage = lastPage, getDetails()"
                />
              </div>
            </b-col>
          </b-row>

          <!-- <div class="d-flex justify-content-center mb-2">
            <div v-if="status !== 0">
              <b-button
                v-b-modal.approve-all-employee
                md="4"
                class="p-1 saj-button btn-info"
                style=""
              >

                {{ $t('Approve All') }}
              </b-button>
            </div>
            <div v-else>
              <b-button
                md="4"
                class="p-1 saj-button btn-info"
                success
                style=""
                disabled
              >
                {{ $t("All Approved") }}
              </b-button>
            </div>

          </div> -->
        </div>
      </b-overlay>

    </b-card>
    <b-modal
      id="edit-employee"
      :hide-footer="true"
      :hide-header="true"
      :centered="true"
      size="lg"
    >
      <EditEmployee
        :performance="tempPerf"
        :user="tempUser"
        @close="$bvModal.hide('edit-employee')"
        @update-performance="getDetails()"
      />
    </b-modal>
    <b-modal
      id="approve-employee"
      :hide-footer="true"
      :hide-header="true"
      :centered="true"
    >
      <ApproveEmployee
        :performance="performanceId"
        @close="$bvModal.hide('approve-employee')"
        @approve-performance="getDetails()"
      />
    </b-modal>
    <b-modal
      id="approve-all-employee"
      :hide-footer="true"
      :hide-header="true"
      :centered="true"
    >
      <ApproveEmployee
        :all-approve="true"
        @close="$bvModal.hide('approve-all-employee')"
        @approve-all="approveAll()"
      />
    </b-modal>
  </div>
</template>

<script>
import EditEmployee from "@/component/hod/EditEmployee.vue"
import ApproveEmployee from "@/component/hod/ApproveEmployee.vue"
import Ripple from "vue-ripple-directive"
// import ApproveBulkEmployee from "@/component/hod/ApproveBulkEmployee.vue"
import {
  BCard,
  // BCardHeader,
  // BCardBody,
  BRow,
  BCol,
  BTable,
  // BImg,
  BButton,
  // BContainer,
  BFormInput,
  // BFormDatepicker,
  BFormSelect,
  VBTooltip,
  VBPopover,
  BPopover,
  BModal,
  BOverlay,
  BSpinner,
} from "bootstrap-vue"
// import { forEach } from "postcss-rtl/lib/affected-props"
import SAJToast from '@/component/saj-toastification.vue'
import {
  max_value,
  min_value,
} from 'vee-validate/dist/rules'
import { ValidationProvider, extend } from 'vee-validate'
import { required } from '@validations'

extend('max_value', max_value)
extend('min_value', min_value)

export default {
  components: {
    BRow,
    BCol,
    BCard,
    // BCardHeader,
    // BCardBody,
    BButton,
    // BContainer,
    BFormInput,
    BFormSelect,
    BTable,
    EditEmployee,
    ApproveEmployee,
    // eslint-disable-next-line vue/no-unused-components
    BPopover,
    // ApproveBulkEmployee,
    BModal,
    BOverlay,
    BSpinner,
    ValidationProvider,
  },
  directives: {
    "b-popover": VBPopover,
    'b-tooltip': VBTooltip,
    Ripple,
  },

  data() {
    return {
      required,
      tempUser: null,
      tempPerf: null,
      status: null,
      showEdit: null,
      show: true,
      // execObj: [],
      approveList: [],
      // isModalABulkVisible: false,
      performanceId: "",
      userId: null,
      employeeList: [],
      role: "",
      filter: "",
      searchname: null,
      selectedposition: '',
      // selectedDistrict: '',
      selectedWork: null,
      selectedCategory: null,
      selectedEmployeeID: null,
      companyID: '',
      empCategory: null,
      StatusJdKra: null,
      allEmpID: [],
      categoryList: [{ value: null, text: `${this.$t('Choose Category')}`, disabled: true }],
      // WorkList: [],
      WorkList: [{ value: null, text: `${this.$t('Choose Work Location')}`, disabled: true }],
      allWork: [],
      empIDList: [{ value: null, text: `${this.$t('Choose Employee Id')}`, disabled: true }],
      items: [

      ],

      fields: [
        { key: 'employee_number', label: 'Id', thClass: 'text-center' },
        {
          key: 'employee_name', stickyColumn: true, label: 'Name', thClass: 'text-center',
        },
        {
          key: 'category', label: 'Category', thClass: 'text-center',
        },
        { key: 'work', label: 'Work Location', thClass: 'text-center' },
        { key: 'jd_kra', label: 'Kra/Jd', thClass: 'text-center' },
        { key: 'competency_core', label: 'Core', thClass: 'text-center' },
        { key: 'competency_behaviour', label: 'Behavioural', thClass: 'text-center' },
        { key: 'training', label: 'Training & Development', thClass: 'text-center' },
        { key: 'contribution', label: 'Contribution', thClass: 'text-center' },
        { key: 'total_score', label: 'Total Score', thClass: 'text-center' },
        // { key: 'action', label: 'Action', thClass: 'text-center' },
      ],
      currentPage: 1,
      perPage: null,
      to: null,
      from: null,
      rows: null,
      lastPage: null,
      currIdx: null,
    }
  },
  computed: {

  },
  watch: {

    // eslint-disable-next-line func-names
    '$i18n.locale': function () {
      this.WorkList = this.WorkList.map(x => {
        if (x.value === null){
          return {
            ...x,
            text: this.$t('Choose Work Location'),
          }
        }
        return x
      })
      this.categoryList = this.categoryList.map(x => {
        if (x.value === null){
          return {
            ...x,
            text: this.$t('Choose Category'),
          }
        }
        return x
      })
      this.empIDList = this.empIDList.map(x => {
        if (x.value === null){
          return {
            ...x,
            text: this.$t('Choose Employee Id'),
          }
        }
        return x
      })
    },

  },
  mounted() {
    this.getDetails()
    // this.getDistrict()
    this.getEmployeeID()
    this.getCategory()
    this.getStatus()
    // this.getWorkLocation()
  },
  methods: {
    // getDistrict() {
    //   this.$axios.get(`${this.$baseUrl}/branch/get_district_by_hod`).then(response => {
    //     this.allDistrict = response.data.data
    //     // console.log("ROLES SINI!", this.allRoles);

    //     this.allDistrict.forEach(district => {
    //       this.districtList.push(district.district_name)
    //       // console.log("hoi role", role.name);
    //     })
    //   })
    // },
    getWorkLocation() {
      this.$axios.get(`${this.$baseUrl}/branch/get_branch_by_company?company_id=${this.companyID}`).then(response => {
        this.allWork = response.data.data
        // console.log('wl', response)
        // console.log("work SINI!", this.allWork)
        // this.WorkList = [{ value: "", text: `${this.$t('Choose Work Location')}`, disabled: true }]
        this.allWork.forEach(b => {
          this.WorkList.push({
            text: b.name,
            value: b.id,
          })
        })
        // this.allWork.forEach(work => {
        //   this.WorkList.push(work.name)
        //   // console.log("tengok sini", work.name)
        // })
      })
    },
    getEmployeeID() {
      this.$axios.get(`${this.$baseUrl}/hod/getEmpByHOD`).then(response => {
        this.allEmpID = response.data.data

        this.allEmpID.forEach(empID => {
          this.empIDList.push(empID.employee.employee_number)
          // console.log("hoi role", role.name);
        })
      })
    },
    getCategory(){
      this.$axios.get(`${this.$baseUrl}/getEmployeeCategory`).then(response => {
        this.cat = response.data.data

        this.cat.forEach(categ => {
          this.categoryList.push({
            text: categ,
            value: categ.toLowerCase(),
          })

          // console.log("hoi role", role.name);
        })
        // console.log('cat', this.categoryList)
      })
    },
    approveAll(){
      this.employeeList.forEach(item => {
        if (item.performance_approved_at === null) {
          this.approveList.push(
            item.performance_id,
          )
        }
      })

      const data = new FormData()
      data.append(`performance_id[]`, this.approveList)
      this.approveList.forEach(k => {
        data.append(`performance_id[]`, k)
      })
      this.$axios.post(`${this.$baseUrl}/performance_scale/approve_performance_all`, data).then(() => {
        // console.log(response)
        this.$bvModal.hide('approve-all-employee')
        this.getDetails()
        this.$toast(
          {
            component: SAJToast,
            props: {
              title: `${this.$t('Successfully approved all employees')}!`,
              icon: 'CheckCircleIcon',
              variant: 'success',
              titleColor: '#000',
            },
          },
          {
            position: "top-right",
            type: 'info',
          },
        )
      })
    },

    clearButton() {
      this.selectedDistrict = ""
      this.selectedposition = ""
      this.selectedEmployeeID = null
      this.selectedCategory = null
      this.searchname = null
      this.selectedWork = null
      this.currentPage = 1
      this.getDetails()
    },
    getStatus() {
      this.$axios.get(`${this.$baseUrl}/get_approved_status`).then(response => {
        this.status = response.data.data.total_pending
      })
    },

    getDetails() {
      this.show = true
      // console.log('performance scale sini', )
      const data = new FormData()
      // data.append('district_name', this.selectedDistrict)
      if (this.selectedWork !== null){
        data.append('branch_id', this.selectedWork)
      }
      if (this.selectedCategory !== null){
        data.append('category', this.selectedCategory)
      }
      if (this.selectedEmployeeID !== null){
        data.append('employee_number', this.selectedEmployeeID)
      }
      if (this.searchname !== null){
        data.append('employee_name', this.searchname)
      }
      data.append('page', this.currentPage)

      // // eslint-disable-next-line no-restricted-syntax
      // for (const pair of data.entries()) {
      //   console.log(`${pair[0]}, ${pair[1]}`)
      // }

      this.$axios.post(`${this.$baseUrl}/performance_scale_by_hod`, data).then(response => {
        this.employeeList = response.data.data
        // console.log('get details', response)

        const paging = response.data
        // this.from = paging.from
        // this.to = paging.to
        // this.rows = paging.total
        this.perPage = paging.per_page
        this.lastPage = paging.last_page
        this.currIdx = this.currentPage

        if (paging.total === 0){
          this.to = 0
          this.from = 0
          this.rows = 0
        } else {
          this.from = paging.from
          this.to = paging.to
          this.rows = paging.total
        }

        // this.performanceId = response.data.data[0].performance_id
        // this.userId = response.data.data[0].user_id
        this.companyID = response.data.data[0].subsidiary_id
        this.show = false
        this.WorkList = [{ value: null, text: `${this.$t('Choose Work Location')}`, disabled: true }]
        this.getWorkLocation()
      }).catch(() => {
        // console.log(error.response)
        this.show = false
      })
    },

  },

}
</script>
<style scoped>
::-webkit-scrollbar {
    width: 0px;
    height: 0px;
}

:hover::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}

::-webkit-scrollbar-thumb {
    border-radius: 10px !important;
}

:hover::-webkit-scrollbar-thumb {

    /* background-color: #a0a0a5 !important; */
    background-color: rgb(255, 0, 0)
}

::-webkit-scrollbar-thumb:hover {
    border: 2px solid #bbb9b9 !important;
}
</style>

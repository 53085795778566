<template>
  <div
    class="p-2"
    style="width: auto"
  >
    <h1
      class="saj-title"
      style="text-align: center"
    >
      <p v-if="allApprove">
        {{ $t("Are you sure you want to approve all") }}?
      </p>
      <p v-else>
        {{ $t("Are you sure you want to approve") }}?
      </p>
    </h1>
    <!-- performance id {{ performance }} -->
    <div
      class="d-flex justify-content-center"
      style="
              <!-- background:pink; -->
            "
    ><button
       class="btn m-1 saj-subtitle"
       aria-label="submit modal"
       style="
                color: white;
                background: #0b103c !important;
                border-color: #0b103c !important;
              "
       @click="allApprove ? approveAllUser() : approveProject()"
     >
       {{ $t("Confirm") }}

       <!-- {{ pmID }} -->
     </button>
      <button
        class="btn btn-close m-1 saj-button"
        aria-label="Close modal"
        style="
                color: white;
                background: #ff0000;
              "
        @click="close()"
      >
        {{ $t("Cancel") }}
      </button>

    </div>
  </div>

</template>
<script>
import {

} from "bootstrap-vue"
import SAJToast from '@/component/saj-toastification.vue'
import { mapGetters } from 'vuex'

export default {
  components: {
  },
  props: {
    performance: {
      type: Number,
      default: null,
    },
    allApprove: {
      type: Boolean,
      default: false,
    },
    isDivision: {
      type: null,
      default: null,
    },
  },
  data() {
    return {
      pmID: null,
    }
  },
  computed: {
    ...mapGetters(['roles']),
  },
  mounted() {
    // console.log('roles', this.roles.selectedRole)
  },

  methods: {
    close() {
      this.$emit('close')
    },
    approveAllUser(){
      this.$emit('approve-all')
    },
    approveProject() {
      const data = new FormData()
      data.append(`performance_id[]`, this.performance)

      const currRole = this.roles.selectedRole

      if (currRole === 3){
        data.append(`sv_approve`, 1)
      }

      if (currRole === 5){
        data.append(`hod_approve`, 1)
      }
      // TM
      if (currRole === 4 && this.isDivision === null){
        data.append(`sv_approve`, 1)
      }
      // Head Of Division
      if (currRole === 4 && this.isDivision !== null){
        data.append(`head_division_approve`, 1)
      }

      if (currRole === 6){
        data.append(`hr_approve`, 1)
      }

      if (currRole === 1){
        data.append(`shr_approve`, 1)
      }

      // eslint-disable-next-line no-restricted-syntax
      // for (const pair of data.entries()) {
      //   console.log(`${pair[0]}, ${pair[1]}`)
      // }

      this.$axios.post(`${this.$baseUrl}/performance/approve`, data).then(() => {
        setTimeout(() => {
          // console.log('approve 1 on 1')
          this.$emit('close')
          this.$emit('approve-performance')
          this.$toast(
            {
              component: SAJToast,
              props: {
                title: `${this.$t('Successfully approved')}!`,
                icon: 'CheckCircleIcon',
                variant: 'success',
                titleColor: '#000',
              },
            },
            {
              position: "top-right",
              type: 'info',
            },
          )
        }, 1000)
      }).catch(error => {
        this.$toast(
          {
            component: SAJToast,
            props: {
              title: error.response.data.message,
              icon: "XIcon",
              variant: "danger",
              iconBg: '#e80202',
              titleColor: "#000",
            },
          },
          {
            position: "top-right",
            type: 'error',
          },
        )
        this.$emit('close')

        // this.$emit('approve-performance')
        // console.log('message:', error.response.data.message)
      })
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";

.eventtext {
  font-family: "Poppins";
  font-weight: normal;
  font-size: 25px;
  line-height: 30px;
  letter-spacing: 0.4px;
}
</style>
